import { print } from "graphql";
import gql from "graphql-tag";

import {
  categoryFragment,
  tagFragment,
  recipeFragment,
  salesLandingPageFragment,
  recipeFragmentExtended,
  salesLandingPageFragmentExtended,
  recipeStubFragment,
} from "./fragments";

export const GetAllCategories = print(gql`
  query GetAllCategories(
    $where: CategoryFilter!
    $limit: Int!
    $preview: Boolean!
  ) {
    collection: categoryCollection(
      limit: $limit
      preview: $preview
      where: $where
    ) {
      total
      skip
      limit
      items {
        ...RecipeCategoryFields
      }
    }
  }
  ${categoryFragment}
`);

export const GetCategory = print(gql`
  query GetCategory($where: CategoryFilter!, $preview: Boolean!) {
    collection: categoryCollection(limit: 1, preview: $preview, where: $where) {
      items {
        ...RecipeCategoryFields
      }
    }
  }
  ${categoryFragment}
`);

export const GetAllTags = print(gql`
  query GetAllTags($where: TagFilter!, $limit: Int!, $preview: Boolean!) {
    collection: tagCollection(limit: $limit, preview: $preview, where: $where) {
      total
      skip
      limit
      items {
        ...TagFields
      }
    }
  }
  ${tagFragment}
`);

export const GetTag = print(gql`
  query GetTag($where: TagFilter!, $preview: Boolean!) {
    collection: tagCollection(limit: 1, preview: $preview, where: $where) {
      items {
        ...TagFields
      }
    }
  }
  ${tagFragment}
`);

export const GetAllRecipes = print(gql`
  query GetAllRecipes(
    $preview: Boolean!
    $limit: Int!
    $where: RecipeFilter!
    $order: [RecipeOrder]
    $skip: Int!
  ) {
    collection: recipeCollection(
      limit: $limit
      preview: $preview
      where: $where
      order: $order
      skip: $skip
    ) {
      total
      skip
      limit
      items {
        ...RecipeFields
      }
    }
  }
  ${recipeFragment}
`);
export const GetAllRecipeStubs = print(gql`
  query GetAllRecipes(
    $preview: Boolean!
    $limit: Int!
    $where: RecipeFilter!
    $order: [RecipeOrder]
    $skip: Int!
  ) {
    collection: recipeCollection(
      limit: $limit
      preview: $preview
      where: $where
      order: $order
      skip: $skip
    ) {
      total
      skip
      limit
      items {
        ...RecipeStubFields
      }
    }
  }
  ${recipeStubFragment}
`);

export const GetAllRecipeSlugs = print(gql`
  query GetAllRecipeSlugs(
    $preview: Boolean!
    $limit: Int!
    $where: RecipeFilter!
    $order: [RecipeOrder]
    $skip: Int!
  ) {
    collection: recipeCollection(
      limit: $limit
      preview: $preview
      where: $where
      order: $order
      skip: $skip
    ) {
      total
      skip
      limit
      items {
        slug
      }
    }
  }
`);

export const GetRecipe = print(gql`
  query GetRecipe($preview: Boolean!, $where: RecipeFilter!) {
    collection: recipeCollection(limit: 1, preview: $preview, where: $where) {
      total
      skip
      limit
      items {
        ...RecipeFields
        ...RecipeFieldsExtended
      }
    }
  }
  ${recipeFragment}
  ${recipeFragmentExtended}
`);

export const GetAllSalesLandingPages = print(gql`
  query GetAllSalesLandingPages(
    $where: SalesLandingPageFilter!
    $limit: Int!
    $preview: Boolean!
  ) {
    collection: salesLandingPageCollection(
      limit: $limit
      preview: $preview
      where: $where
    ) {
      total
      skip
      limit
      items {
        ...SalesLandingPageFields
      }
    }
  }
  ${salesLandingPageFragment}
`);

export const GetSalesLandingPage = print(gql`
  query GetSalesLandingPage(
    $where: SalesLandingPageFilter!
    $preview: Boolean!
  ) {
    collection: salesLandingPageCollection(
      limit: 1
      preview: $preview
      where: $where
    ) {
      items {
        ...SalesLandingPageFields
      }
    }
  }
  ${salesLandingPageFragment}
  ${salesLandingPageFragmentExtended}
`);

export const GetAllRecipeSitemapEntries = print(gql`
  query getAllRecipeSitemapEntries(
    $preview: Boolean!
    $limit: Int!
    $where: RecipeFilter!
    $order: [RecipeOrder]
    $skip: Int!
  ) {
    collection: recipeCollection(
      limit: $limit
      preview: $preview
      where: $where
      order: $order
      skip: $skip
    ) {
      total
      skip
      limit
      items {
        slug
        sys {
          publishedAt
        }
      }
    }
  }
`);

export const GetAllCategorySitemapEntries = print(gql`
  query GetAllCategorySitemapEntries(
    $where: CategoryFilter!
    $limit: Int!
    $preview: Boolean!
  ) {
    collection: categoryCollection(
      limit: $limit
      preview: $preview
      where: $where
    ) {
      total
      skip
      limit
      items {
        slug
        sys {
          publishedAt
        }
      }
    }
  }
`);
