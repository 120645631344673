import extractCollection from "shared-lib/data/graphql/extractCollection";
import { fetchFromRecipeSpace } from "shared-lib/data/graphql/fetchGraphQL";

import { GetAllRecipes } from "./queries";

type Props = {
  limit?: number;
  category?: string;
  notCategory?: string;
  tag?: string;
  notTag?: string;
  order?: string[];
  query?: string;
  preview?: boolean;
  recent?: boolean;
  excludeMultiple?: string[];
  excludeId?: string;
  exclude?: string[];
  excludeSlug?: string[];
  pageNumber?: number;
  skip?: number;
  tags?: string[];
};

export default async function getAllRecipes({
  limit = 3, //max 36 otherwise exceeds complexity limit
  category = "",
  notCategory = "",
  tag = "",
  notTag = "",
  order = [],
  query = "",
  preview = false,
  recent = false,
  exclude = [],
  excludeId = "",
  excludeSlug = [],
  pageNumber = 1,
  skip = 0,
  tags = [],
}: Props): Promise<Collection<Recipe>> {
  const where: any = { sys: {} };
  if (category) {
    where.category = { slug: category };
    tags.push(category);
  }
  if (notCategory) {
    where.category = { slug_not: notCategory };
  }
  if (tag) {
    where.tags = { slug: tag };
    tags.push(tag);
  }
  if (notTag) {
    where.tags = { slug_not: notCategory };
  }
  if (recent) {
    const cutOff = new Date();
    cutOff.setDate(cutOff.getDate() - 30);
    where.sys.publishedAt_gte = cutOff.toISOString();
  }
  if (exclude.length) {
    where.sys.id_not_in = exclude;
  }
  if (excludeSlug.length) {
    where.slug_not_in = excludeSlug;
  }
  if (excludeId) {
    where.sys.id_not = excludeId;
  }
  if (query) {
    where.OR = [{ title_contains: query }, { richDescription_contains: query }];
  }
  const fetchResponse = await fetchFromRecipeSpace(
    {
      query: GetAllRecipes,
      variables: {
        where,
        order: order.length ? order : undefined,
        limit,
        preview,
        skip: pageNumber > 1 ? limit * pageNumber + skip : skip,
      },
    },
    ["GetAllRecipes", "collection", ...tags],
  );
  if (fetchResponse.errors) {
    throw new Error(fetchResponse.errors[0].message);
  }
  return extractCollection(fetchResponse);
}
